<script setup>
import RadioGroup from "../elements/RadioGroup.vue";
import RadioGroupOption from "../elements/RadioGroupOption.vue";
import DeviceAutocomplete from "./DeviceAutocomplete.vue";
import {getAccountDevices, getAccountGroups} from "@gpx-spa/queries";
import { UserRole } from "@gpx-spa/constants";
import Spinner from "../elements/Spinner.vue";
import {ref, watch} from "vue";
import GroupsAutocomplete from "./GroupsAutocomplete.vue";
import Toggle from "../elements/Toggle.vue";

const role = defineModel("role", "all");
const deviceIds = defineModel("deviceIds");
const groupIds = defineModel("groupIds");
const fullDeviceAccess = defineModel("fullDeviceAccess", true);
const props = defineProps(['accountId'])
const accountId = ref(props.accountId)

watch(props, () => accountId.value = props.accountId);
watch(role, () => {
  if (role.value === UserRole.Admin) {
    fullDeviceAccess.value = true;
  }
})

const { data: accountDevices, isFetching: isFetchingDevices } = getAccountDevices(accountId);
const { data: accountGroups, isFetching: isFetchingGroups } = getAccountGroups(accountId);
</script>

<template>
  <div>
    <RadioGroup class="mt-[19px]">
      <RadioGroupOption
          v-model="role"
          :value="UserRole.Admin"
          :label="'Administrator'"
          :description="'Access to all devices, can invite users'"
      />
      <RadioGroupOption
          v-model="role"
          :value="UserRole.Manager"
          :label="'Account Manager'"
          :description="'Account Manager'"
      />
      <RadioGroupOption
          v-model="role"
          :value="UserRole.User"
          :label="'Power User'"
          :description="'Power User'"
      />
      <RadioGroupOption
          v-model="role"
          :value="UserRole.Viewer"
          :label="'Viewer'"
          :description="'Read only access'"
      />
    </RadioGroup>
    <template v-if="role !== UserRole.Admin">
      <div class="mt-[29px]">
        <div class="flex items-center gap-x-[12px]">
          <Toggle v-model="fullDeviceAccess" />
          <span class="text-gpx_black-300 inline-block text-sm font-semibold">
            Grant access to {{fullDeviceAccess ? 'all' : 'selected' }} devices
          </span>
        </div>
      </div>
      <div class="mt-4" v-if="!fullDeviceAccess">
        <Spinner v-if="isFetchingDevices || isFetchingGroups" />
        <template v-else>
          <DeviceAutocomplete v-model="deviceIds" :devices="accountDevices" />
          <GroupsAutocomplete v-model="groupIds" :groups="accountGroups" class="mt-4"/>
        </template>
      </div>
    </template>
  </div>
</template>