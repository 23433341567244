import { defineAbility } from '@casl/ability';
import store from './store';
import { UserRole } from '@gpx-spa/constants';

export default () => defineAbility((can, cannot) => {
    const user = store.getters['auth/user'];
    if (!user) return;

    const account = store.getters['auth/account'];
    if (!account) return;

    const accountSettingIsOn = store.getters['auth/accountSettingIsOn'];
    const role = user.account_roles[account.id];

    if (role === UserRole.Admin) {
      can('manage', 'Geofences');
      can('manage', 'Anchors');
      can('manage', 'Account');
    }
    if ([UserRole.Admin, UserRole.Manager].indexOf(role) > -1) {
      accountSettingIsOn('dashboards') && can('view', 'Dashboard');
      can('manage', 'Users');
      can('manage', 'Reports');
    }
    if (role !== UserRole.Viewer) {
      can('manage', 'Shipments');
      can('manage', 'Devices');
      can('manage', 'Groups');
      can('manage', 'Rules');
    }
});
