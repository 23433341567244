<template>
  <ModalLayout title="Invite New User">
    <div class="rounded-t-[10px] bg-white pt-[41px]">
      <div
        @click.prevent="emit('close')"
        class="absolute right-[24px] top-[36px] flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full border border-gpx_gray-800 sm:right-[40px]"
      >
        <XMarkIcon
          class="heroicon-stroke-w-1.4 h-[20px] w-[20px] text-gpx_gray-200"
        />
      </div>
      <div
        class="mx-[24px] border-b border-b-gpx_gray-800 pb-[18px] sm:mx-[40px]"
      >
        <div class="mb-[2px] text-gpx_sm font-medium text-gpx_gray-200">
          Inviting
        </div>
        <div class="text-gpx_2xl font-semibold text-lms_black-500">
          New Users
        </div>
      </div>
      <div class="px-[24px] sm:px-[40px]">
        <div class="mt-[35px] flex flex-col space-y-[18px]">
          <UITextarea
            placeholder="mark@gmail.com, bob@email.com, ..."
            v-model="form.emails"
            label="Emails"
          ></UITextarea>
        </div>
        <div class="mt-[30px]">
          <div class="mb-[3px] text-gpx_base font-semibold text-lms_black-400">
            User Role
          </div>
          <div class="text-gpx_sm text-gpx_gray-100">
            Grant user access to all or a specific devices.
          </div>
        </div>
        <PermissionPicker
          :accountId="accountId"
          v-model:role="form.role"
          v-model:device-ids="form.device_ids"
          v-model:group-ids="form.group_ids"
          v-model:full-device-access="form.full_device_access"
        ></PermissionPicker>
      </div>
    </div>
    <div class="rounded-b-[10px] bg-white px-[24px] py-[32px] sm:px-[40px]">
      <UIButton
        :loading="isInvitingUser"
        :disabled="isInvitingUser"
        :label="isInvitingUser ? 'Inviting' : 'Invite Users'"
        @click="handleInviteUser"
      />
    </div>
  </ModalLayout>
</template>

<script setup>
import { ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import ModalLayout from '@/layouts/ModalLayout.vue';
import UIButton from '@/components/ui/UIButton.vue';
import { PermissionPicker } from '@gpx-spa/ui';
import UITextarea from '@/components/ui/UITextarea.vue';
import { UserRole } from '@gpx-spa/constants'

const emit = defineEmits(['close']);
const store = useStore();
const toast = useToast();

const isInvitingUser = ref(false);
const form = reactive({
  emails: '',
  role: UserRole.Manager,
  device_ids: [],
  group_ids: [],
  full_device_access: true,
});

const accountId = computed(() => store.getters['auth/accountId']);
const activeModal = computed(() => store.getters['modal/active']);
const emailsArray = computed(() => {
  return form.emails.split(/,|;/).map(email => email.trim())
});

const handleInviteUser = async () => {
  if(!emailsArray.value.length) {
    toast.error('Incorrect list of emails provided.');
    return;
  }

  isInvitingUser.value = true;
  try {
    await store.dispatch('user/invite', {
      accountId: accountId.value,
      params: {
        ...form,
        emails: emailsArray.value,
      },
    });
    toast.success('The user was successfully invited');
    activeModal.value.params.onSuccess();
    emit('close');
  } catch (e) {
    toast.error(e.response?.data?.message || 'An error occurred. Please try again later.');
  } finally {
    isInvitingUser.value = false;
  }
};
</script>
