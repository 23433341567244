<template>
  <div
    class="hidden text-black/50 md:flex md:w-full md:items-center md:gap-x-4"
  >
    <UILink
      to="/trackers"
      class="pb-px pt-[2px] text-lgmx_15 font-medium leading-6"
    >
      Trackers
    </UILink>
    <UILink
      to="/geofences"
      class="pb-px pt-[2px] text-lgmx_15 font-medium leading-6"
    >
      Geofences
    </UILink>
    <UILink
      v-if="$can('view', 'Dashboard')"
      to="/dashboards"
      class="pb-px pt-[2px] text-lgmx_15 font-medium leading-6"
    >
      Dashboards
    </UILink>
    <UILink
      v-if="canTrackShipments"
      to="/shipments"
      class="pb-px pt-[2px] text-lgmx_15 font-medium leading-6"
    >
      Shipments
    </UILink>
    <UILink
      to="/alerts"
      class="pb-px pt-[2px] text-lgmx_15 font-medium leading-6"
    >
      Alerts
    </UILink>
    <UILink
      to="/reports"
      class="pb-px pt-[2px] text-lgmx_15 font-medium leading-6"
    >
      Reports
    </UILink>
    <UILink
      v-if="hasRulesEngine"
      to="/rules"
      class="pb-px pt-[2px] text-lgmx_15 font-medium leading-6"
    >
      Rules
    </UILink>
    <UILink
      v-if="canManage"
      to="/manage"
      class="pb-px pt-[2px] text-lgmx_15 font-medium leading-6"
    >
      Manage
    </UILink>
    <NavIntercomLauncher
      class="pb-px pt-[2px] text-lgmx_15 font-medium leading-6 md:hidden lg:block"
    />
    <div class="ml-auto flex items-center">
      <NavAccountDropdown />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NavAccountDropdown from '@/components/_common/nav/NavAccountDropdown.vue';
import NavIntercomLauncher from '@/components/_common/nav/NavIntercomLauncher.vue';
import { UserRole } from '@gpx-spa/constants';

export default {
  components: {
    NavAccountDropdown,
    NavIntercomLauncher,
  },
  computed: {
    ...mapGetters({
      account: 'auth/account',
      user: 'auth/user',
      role: 'auth/role',
    }),
    canTrackShipments() {
      return this.account?.can_track_shipments;
    },
    hasRulesEngine() {
      return this.account?.has_rules_engine;
    },
    canManage() {
      return [UserRole.Admin, UserRole.Manager].includes(this.role);
    },
  },
};
</script>
